<template>
  <div ref="targetHeight">
    <validation-provider
      tag="div"
      ref="provider"
      :vid="'prefecture-' + item.id"
      :name="item.title"
      :rules="{ required: requiredCheck }"
      v-slot="validationContext"
    >
      <label
        :for="'prefecture-' + item.id"
        class="iv-label"
        :class="{ 'iv-invalid': !getValidationState(validationContext) }"
      >
        {{ item.title }}
        <base-required :required="item.is_required" />
      </label>

      <b-row>
        <b-col md="4">
          <b-form-select
            v-model="item.value"
            stacked
            :state="getValidationState(validationContext)"
            @change="onChange"
          >
            <b-form-select-option value="">
              {{ $t("form.selected_message") }}
            </b-form-select-option>
            <b-form-select-option
              v-for="list in prefectures"
              :key="list.id"
              :value="list.label"
              :id="'prefecture-item-' + list.id"
              >{{ list.label }}</b-form-select-option
            >
          </b-form-select>

          <b-form-invalid-feedback
            :id="'prefecture-feedback-' + item.id"
            class="d-block"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-form-text :id="'prefecture-help-' + item.id">
        {{ item.annotation_text }}
      </b-form-text>
    </validation-provider>
  </div>
</template>

<script>
import height from "@/mixins/elementHeight";

export default {
  mixins: [height],

  props: {
    item: Object,
  },

  data() {
    return {
      prefectures: [],
    };
  },

  computed: {
    requiredCheck() {
      return this.item.is_required === 1 ? true : false;
    },
  },

  created() {
    this.prefectures = JSON.parse(localStorage.getItem("iv_mst_prefectures"));
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    async onChange() {
      const itemId = this.$route.query.id;
      if (this.item.id == itemId) {
        const field = await this.$refs.provider.validate();
        if (!field.valid) {
          this.$emit("disabled", true);
        } else {
          this.$emit("disabled", false);
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
