import axios from "axios";
import store from "@/store/index.js";

const url = process.env.VUE_APP_ZIP_SERVER_API;

export default {
  index(zip) {
    const apiKey = store.state.zipserverKey;
    return axios.post(
      url,
      {
        zipcode: zip,
      },
      {
        headers: {
          "X-Zipserver": apiKey,
        },
      }
    );
  },
};
