<template>
  <div ref="targetHeight">
    <validation-provider
      tag="div"
      ref="provider"
      :vid="'datalist-' + item.id"
      :name="item.title"
      :rules="{ required: requiredCheck }"
      v-slot="validationContext"
    >
      <label
        :for="'datalist-' + item.id"
        class="iv-label"
        :class="{ 'iv-invalid': !getValidationState(validationContext) }"
      >
        {{ item.title }}
        <base-required :required="item.is_required" />
      </label>

      <b-form-input
        :list="'datalist-' + item.id"
        :placeholder="item.placeholder"
        v-model="item.value"
        :state="getValidationState(validationContext)"
        @input="onInput"
      ></b-form-input>

      <datalist :id="'datalist-' + item.id">
        <option v-for="list in item.datalists.value" :key="list">
          {{ list }}
        </option>
      </datalist>

      <b-form-invalid-feedback :id="'datalist-feedback-' + item.id">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>

      <b-form-text :id="'datalist-help-' + item.id">
        {{ item.annotation_text }}
      </b-form-text>
    </validation-provider>
  </div>
</template>

<script>
import height from "@/mixins/elementHeight";

export default {
  mixins: [height],

  props: {
    item: Object,
  },

  computed: {
    requiredCheck() {
      return this.item.is_required === 1 ? true : false;
    },
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    async onInput() {
      const itemId = this.$route.query.id;
      if (this.item.id == itemId) {
        const field = await this.$refs.provider.validate();
        if (!field.valid) {
          this.$emit("disabled", true);
        } else {
          this.$emit("disabled", false);
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
