import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    confirm: false,
    send: false,
    appId: "",
    formId: "",
    token: "",
    zipserverIsUse: "",
    zipserverKey: "",
    params: {},
    disableHeadline: false,
    removeUrl: "",
  },

  mutations: {
    confirmStatus(state) {
      state.confirm = true;
    },

    sendStatus(state) {
      state.send = true;
    },

    setInitialId(state, { app, form }) {
      state.appId = app;
      state.formId = form;
    },

    setZipserver(state, { use, key }) {
      state.zipserverIsUse = use;
      state.zipserverKey = key;
    },

    setParams(state, { obj }) {
      state.params = obj;
    },

    disableHeadline(state) {
      state.disableHeadline = true;
    },

    removeUrl(state, { url }) {
      state.removeUrl = url;
    },
  },

  actions: {
    confirmStatus({ commit }) {
      commit("confirmStatus");
    },

    sendStatus({ commit }) {
      commit("sendStatus");
    },

    setInitialId({ commit }, { app, form }) {
      commit("setInitialId", { app: app, form: form });
    },

    setZipserver({ commit }, { use, key }) {
      commit("setZipserver", { use: use, key: key });
    },

    setParams({ commit }, { hidden }) {
      commit("setParams", { obj: hidden });
    },

    disableHeadline({ commit }) {
      commit("disableHeadline");
    },

    removeUrl({ commit }, { url }) {
      commit("removeUrl", { url: url });
    },
  },

  modules: {},
});
