<template>
  <div>
    <div v-for="item in items" :key="item.id" class="mb-4">
      <div v-if="item.is_hide === 0 && item.type !== 'comment'">
        <hr class="my-4" />
        <b-row class="justify-content-between">
          <b-col v-if="displayType == 'list_form'" cols="12">
            <p class="text-muted mb-2">{{ item.title }}</p>
          </b-col>
          <b-col v-else-if="displayType == 'step_form'" cols="9" md="10">
            <p class="text-muted mb-2">{{ item.title }}</p>
          </b-col>
          <b-col cols="3" md="2" class="text-right">
            <router-link
              v-if="displayType == 'step_form'"
              :to="{
                name: 'form',
                query: Object.assign({}, $route.query, { id: item.id }),
              }"
              style="white-space: nowrap"
              class="btn btn-secondary btn-sm"
            >
              {{ $t("form.return_link") }}
            </router-link>
          </b-col>
        </b-row>

        <div class="pl-2">
          <ul
            v-if="arrayCheck(answers[item.id]) && answers[item.id].length > 0"
            class="pl-4"
          >
            <li
              v-for="(val, index) in answers[item.id]"
              :key="index"
              class="mb-1"
            >
              {{ val }}
            </li>
          </ul>
          <div
            v-else-if="
              !arrayCheck(answers[item.id]) &&
              answers[item.id] &&
              item.type == 'date'
            "
          >
            {{ dateFormat(answers[item.id]) }}
          </div>
          <div
            v-else-if="!arrayCheck(answers[item.id]) && answers[item.id]"
            v-text="answers[item.id]"
            class="text-pre"
          ></div>
          <div v-else>{{ $t("form.unanswered") }}</div>
        </div>
      </div>
    </div>
    <hr />
    <b-row class="justify-content-center pt-3 mb-5">
      <b-col
        cols="6"
        md="4"
        class="text-center"
        v-if="displayType == 'list_form'"
      >
        <router-link
          :to="{
            name: 'form',
            query: Object.assign({}, $route.query),
          }"
          class="btn iv-btn iv-btn__back w-100"
        >
          {{ $t("form.back") }}
        </router-link>
      </b-col>
      <b-col
        :cols="columnNumber"
        md="4"
        class="text-center"
        v-if="displayType == 'list_form'"
      >
        <b-button
          v-if="btnActive"
          @click="onSubmit()"
          class="iv-btn iv-btn__submit w-100"
        >
          {{ $t("form.submit") }}
        </b-button>
        <b-button v-else disabled class="iv-btn iv-btn__submit w-100">
          <b-spinner small></b-spinner>
        </b-button>
      </b-col>
      <b-col
        :cols="columnNumber"
        md="4"
        class="text-center"
        v-if="displayType == 'step_form'"
      >
        <b-button
          v-if="btnActive"
          @click="onSubmit()"
          class="iv-btn iv-btn__submit w-100"
        >
          {{ $t("form.submit") }}
        </b-button>
        <b-button v-else disabled class="iv-btn iv-btn__submit w-100">
          <b-spinner small></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    answers: Object,
    displayType: String,
  },

  data() {
    return {
      appId: this.$store.state.appId,
      formId: this.$store.state.formId,
      btnActive: true,
    };
  },

  computed: {
    columnNumber() {
      return this.displayType == "list_form" ? 6 : 8;
    },
  },

  created() {
    // ブラウザバック時の処理
    if (this.$browserBackFlg) {
      this.$emit("reset-answers");
    }

    if (Object.keys(this.answers).length === 0) {
      this.$router.push({
        name: "form",
        query: Object.assign({}, this.$route.query),
      });
    } else {
      this.$store.dispatch("confirmStatus");
    }
  },

  methods: {
    arrayCheck(val) {
      return Array.isArray(val);
    },

    dateFormat(date) {
      return this.$moment(date).format("YYYY年MM月DD日");
    },

    onSubmit() {
      this.btnActive = false;
      const self = this;
      const AnswerRepository = self.$repository.get("answer");
      const answer = AnswerRepository.store(self.answers, self.token);
      answer.then(
        function (result) {
          if (result.data.response) {
            self.btnActive = true;
            self.$router.push({ name: "complete" });
          }
        },
        function (error) {
          const res = error.response;
          if (res) {
            self.$swal({
              icon: "error",
              title: self.$t("form.send_error"),
              text: self.$t("form.send_error_message"),
            });
            self.btnActive = true;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss"></style>
