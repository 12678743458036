import Repository from "./repository";
import store from "@/store/index.js";

export default {
  index() {
    const appId = store.state.appId;
    const formId = store.state.formId;
    return Repository.get(`${appId}/${formId}/items/index`);
  },
};
