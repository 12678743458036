<template>
  <div ref="targetHeight">
    <p class="iv-label text-center py-3 m-0 text-pre">{{ item.title }}</p>
  </div>
</template>

<script>
import height from "@/mixins/elementHeight";

export default {
  mixins: [height],

  props: {
    item: Object,
  },
};
</script>

<style lang="scss"></style>
