export default {
  watch: {
    $route(to, from) {
      if (to.query.id !== from.query.id && to.query.id == this.item.id) {
        this.heightCheck();
      }
    },
  },

  mounted() {
    this.$nextTick(function () {
      this.heightCheck();
    });
  },

  methods: {
    async heightCheck() {
      const height = await this.$refs.targetHeight.clientHeight;
      if (height > 0) {
        this.$emit("height", height);
      }
    },
  },
};
