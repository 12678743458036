<template>
  <div>
    <hr />
    <p class="text-center text-pre">{{ message }}</p>
    <hr />

    <div class="text-center">
      <b-button
        v-if="$store.state.removeUrl && $store.state.removeUrl !== ''"
        @click="remove()"
        class="iv-btn iv-btn__submit"
      >
        サイトに戻る
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    answers: Object,
  },

  created() {
    if (Object.keys(this.answers).length === 0) {
      this.$router.push({
        name: "form",
        query: Object.assign({}, this.$route.query),
      });
    } else {
      this.$store.dispatch("sendStatus");
    }
  },

  methods: {
    remove() {
      window.location.href = this.$store.state.removeUrl;
      return true;
    },
  },
};
</script>

<style lang="scss"></style>
