import answerRepository from "./answerRepository";
import formRepository from "./formRepository";
import itemRepository from "./itemRepository";
import masterRepository from "./masterRepository";
import zipserverRepository from "./zipserverRepository";

const repositories = {
  answer: answerRepository,
  form: formRepository,
  item: itemRepository,
  master: masterRepository,
  zipserver: zipserverRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
