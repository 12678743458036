<template>
  <div ref="targetHeight">
    <validation-provider
      tag="div"
      ref="provider"
      :vid="'select-' + item.id"
      :name="item.title"
      :rules="{ required: requiredCheck }"
      v-slot="validationContext"
    >
      <label
        :for="'select-' + item.id"
        class="iv-label"
        :class="{ 'iv-invalid': !getValidationState(validationContext) }"
      >
        {{ item.title }}
        <base-required :required="item.is_required" />
      </label>

      <b-form-select
        v-model="item.value"
        stacked
        :state="getValidationState(validationContext)"
        @change="onChange"
      >
        <b-form-select-option value="">
          {{ $t("form.selected_message") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="option in item.options"
          :key="option.id"
          :value="option.label"
          :id="'select-item-' + option.id"
          >{{ option.label }}</b-form-select-option
        >
      </b-form-select>

      <b-form-invalid-feedback
        :id="'select-feedback-' + item.id"
        class="d-block"
      >
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>

      <b-form-text :id="'select-help-' + item.id">
        {{ item.annotation_text }}
      </b-form-text>
    </validation-provider>
  </div>
</template>

<script>
import height from "@/mixins/elementHeight";

export default {
  mixins: [height],

  props: {
    item: Object,
  },

  data() {
    return {
      id: "",
      status: "",
    };
  },

  computed: {
    requiredCheck() {
      return this.item.is_required === 1 ? true : false;
    },
  },

  mounted() {
    if (this.item.value) {
      var tmp = this.item.options.filter((e) => e.label == this.item.value);
      var option = Object.keys(tmp[0].condition).length;
      if (option > 0) {
        this.id = tmp[0].condition.action;
        this.status = tmp[0].condition.do;
      }
    }
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    async onChange() {
      const itemId = this.$route.query.id;
      if (this.item.id == itemId) {
        const field = await this.$refs.provider.validate();
        if (!field.valid) {
          this.$emit("disabled", true);
        } else {
          this.$emit("disabled", false);
        }
      }

      if (this.id) {
        this.$emit("remove", this.id, this.status);
      }
      if (this.item.value) {
        var tmp = this.item.options.filter((e) => e.label == this.item.value);
        var option = Object.keys(tmp[0].condition).length;
        if (option > 0) {
          var id = tmp[0].condition.action;
          var status = tmp[0].condition.do;
          this.id = id;
          this.status = status;
          this.$emit("action", id, status);
        } else if (option === 0) {
          this.id = "";
          this.status = "";
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
