<template>
  <div ref="targetHeight">
    <validation-provider
      tag="div"
      ref="provider"
      :vid="'range-' + item.id"
      :name="item.title"
      :rules="{ required: requiredCheck }"
      v-slot="validationContext"
    >
      <div>
        <label
          :for="'range-' + item.id"
          class="iv-label"
          :class="{ 'iv-invalid': !getValidationState(validationContext) }"
        >
          {{ item.title }}
          <base-required :required="item.is_required" />
        </label>
      </div>

      <div class="mb-2">
        <span
          class="iv-form__range--count"
          :class="{ 'target-hover': activeHover }"
          ref="count"
        >
          {{ item.value }}
        </span>
      </div>

      <b-form-input
        :id="'range-' + item.id"
        v-model="item.value"
        type="range"
        :min="item.range.min"
        :max="item.range.max"
        :step="item.range.step"
        :state="getValidationState(validationContext)"
        @input="moveNumber"
        @mouseover="activeHover = true"
        @mouseleave="activeHover = false"
        ref="width"
      ></b-form-input>

      <div class="d-flex justify-content-between">
        <span>{{ item.range.min }}</span
        ><span>{{ item.range.max }}</span>
      </div>

      <b-form-invalid-feedback
        :id="'range-feedback-' + item.id"
        class="d-block"
      >
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>

      <b-form-text :id="'range-help-' + item.id">
        {{ item.annotation_text }}
      </b-form-text>
    </validation-provider>
  </div>
</template>

<script>
import height from "@/mixins/elementHeight";

export default {
  mixins: [height],

  props: {
    item: Object,
  },

  data() {
    return {
      activeHover: false,
    };
  },

  computed: {
    requiredCheck() {
      return this.item.is_required === 1 ? true : false;
    },
  },

  watch: {
    $route(to) {
      if (to.query.id == this.item.id) {
        this.moveNumber();
      }
    },
  },

  created() {
    const send = this.$store.state.send;
    if (this.$browserBackFlg && send) {
      this.item.value = "0";
    }
  },

  mounted() {
    this.moveNumber();
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    async moveNumber() {
      var content = await this.$refs.width.$el;
      var rangeThumb = this.$refs.count;
      rangeThumb.innerHTML = this.item.value;
      var thumbPosition = this.item.value / this.item.range.max;
      rangeThumb.style.left = thumbPosition * (content.clientWidth - 20) + "px";
      this.onInput();
    },

    async onInput() {
      const itemId = this.$route.query.id;
      if (this.item.id == itemId) {
        const field = await this.$refs.provider.validate();
        if (!field.valid) {
          this.$emit("disabled", true);
        } else {
          this.$emit("disabled", false);
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
