<template>
  <div class="iv-form__error">
    <div class="mb-4">
      <div class="iv-form__error--code">{{ code }}</div>
      <div class="iv-form__error--message">{{ $t(`error.${code}`) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: this.$route.name,
    };
  },
};
</script>

<style lang="scss">
.iv-form__error {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;

  &--code {
    font-size: 3rem;
    padding: 0 15px 0 15px;
    text-align: center;
  }

  &--message {
    font-size: 18px;
    text-align: center;
    padding: 10px;
  }
}
</style>
