var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"mb-4"},[(item.is_hide === 0 && item.type !== 'comment')?_c('div',[_c('hr',{staticClass:"my-4"}),_c('b-row',{staticClass:"justify-content-between"},[(_vm.displayType == 'list_form')?_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-muted mb-2"},[_vm._v(_vm._s(item.title))])]):(_vm.displayType == 'step_form')?_c('b-col',{attrs:{"cols":"9","md":"10"}},[_c('p',{staticClass:"text-muted mb-2"},[_vm._v(_vm._s(item.title))])]):_vm._e(),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3","md":"2"}},[(_vm.displayType == 'step_form')?_c('router-link',{staticClass:"btn btn-secondary btn-sm",staticStyle:{"white-space":"nowrap"},attrs:{"to":{
              name: 'form',
              query: Object.assign({}, _vm.$route.query, { id: item.id }),
            }}},[_vm._v(" "+_vm._s(_vm.$t("form.return_link"))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"pl-2"},[(_vm.arrayCheck(_vm.answers[item.id]) && _vm.answers[item.id].length > 0)?_c('ul',{staticClass:"pl-4"},_vm._l((_vm.answers[item.id]),function(val,index){return _c('li',{key:index,staticClass:"mb-1"},[_vm._v(" "+_vm._s(val)+" ")])}),0):(
            !_vm.arrayCheck(_vm.answers[item.id]) &&
            _vm.answers[item.id] &&
            item.type == 'date'
          )?_c('div',[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.answers[item.id]))+" ")]):(!_vm.arrayCheck(_vm.answers[item.id]) && _vm.answers[item.id])?_c('div',{staticClass:"text-pre",domProps:{"textContent":_vm._s(_vm.answers[item.id])}}):_c('div',[_vm._v(_vm._s(_vm.$t("form.unanswered")))])])],1):_vm._e()])}),_c('hr'),_c('b-row',{staticClass:"justify-content-center pt-3 mb-5"},[(_vm.displayType == 'list_form')?_c('b-col',{staticClass:"text-center",attrs:{"cols":"6","md":"4"}},[_c('router-link',{staticClass:"btn iv-btn iv-btn__back w-100",attrs:{"to":{
          name: 'form',
          query: Object.assign({}, _vm.$route.query),
        }}},[_vm._v(" "+_vm._s(_vm.$t("form.back"))+" ")])],1):_vm._e(),(_vm.displayType == 'list_form')?_c('b-col',{staticClass:"text-center",attrs:{"cols":_vm.columnNumber,"md":"4"}},[(_vm.btnActive)?_c('b-button',{staticClass:"iv-btn iv-btn__submit w-100",on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t("form.submit"))+" ")]):_c('b-button',{staticClass:"iv-btn iv-btn__submit w-100",attrs:{"disabled":""}},[_c('b-spinner',{attrs:{"small":""}})],1)],1):_vm._e(),(_vm.displayType == 'step_form')?_c('b-col',{staticClass:"text-center",attrs:{"cols":_vm.columnNumber,"md":"4"}},[(_vm.btnActive)?_c('b-button',{staticClass:"iv-btn iv-btn__submit w-100",on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t("form.submit"))+" ")]):_c('b-button',{staticClass:"iv-btn iv-btn__submit w-100",attrs:{"disabled":""}},[_c('b-spinner',{attrs:{"small":""}})],1)],1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }