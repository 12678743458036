<template>
  <div>
    <List
      v-if="displayType == 'list_form'"
      :items="items"
      :zip="zipButton"
      @set-address="openModal"
      @set-answers="setAnswers"
      @active-item="activeItem"
      @remove-item="removeItem"
      @change-items="changeItems"
    />
    <Step
      v-else-if="displayType == 'step_form'"
      :items="items"
      :zip="zipButton"
      :conditions="conditions"
      @set-address="openModal"
      @set-answers="setAnswers"
      @active-item="activeItem"
      @remove-item="removeItem"
      @change-items="changeItems"
      @remove-condition="removeCondition"
    />
    <b-modal
      ref="addressModal"
      :title="$t('form.address_title')"
      footer-class="d-none"
    >
      <div v-for="(list, index) in address" :key="index">
        <p>
          {{ list.prefecture }}{{ list.city }}{{ list.town }}
          <b-button
            size="sm"
            class="iv-btn__submit ml-3"
            @click="setAddress(index)"
            >{{ $t("form.selected") }}</b-button
          >
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import List from "./FormList";
import Step from "./FormStep";

export default {
  components: {
    List,
    Step,
  },

  props: {
    items: Array,
    displayType: String,
  },

  data() {
    return {
      address: [],
      zipserver: {},
      conditions: [],
      zipButton: true,
      confirm: "",
    };
  },

  mounted() {
    this.confirm = this.$store.state.confirm;
  },

  methods: {
    openModal(val, obj) {
      if (val) {
        this.zipButton = false;
        this.address = [];
        this.zipserver = obj;
        const self = this;
        const zipserverRepository = self.$repository.get("zipserver");
        const zipserver = zipserverRepository.index(val);
        zipserver.then(
          function (result) {
            const res = result.data;
            const status = res.error[0].code;
            if (status == "20000") {
              self.$refs["addressModal"].show();
              self.address = res.address;
            } else {
              self.$swal({
                icon: "error",
                title: self.$t("form.not_search"),
                text: self.$t("form.not_search_message"),
              });
            }
            self.zipButton = true;
          },
          function (error) {
            const res = error.response;
            if (res) {
              self.$swal({
                icon: "error",
                title: self.$t("form.address.error"),
              });
            }
            self.zipButton = true;
          }
        );
      }
    },

    // zipserverの値セット
    setAddress(val) {
      const res = this.address[val];
      const pref = this.zipserver.pref_item_id;
      const city = this.zipserver.city_item_id;
      const town = this.zipserver.town_item_id;
      if (pref > 0) {
        var prefNum = this.items.filter((e) => e.id == pref);
        prefNum[0].value = res.prefecture;
      }
      if (city > 0) {
        var cityNum = this.items.filter((e) => e.id == city);
        cityNum[0].value = res.city;
      }
      if (town > 0) {
        var townNum = this.items.filter((e) => e.id == town);
        townNum[0].value = res.town;
      }
      this.$refs["addressModal"].hide();
    },

    // 回答の値を追加
    setAnswers() {
      this.$emit("set-answers");
    },

    removeCondition(id) {
      var index = this.conditions.indexOf(id);
      if (index > -1) {
        this.conditions.splice(index, 1);
      }
    },

    // 条件発動時（単一選択肢）
    activeItem(id, status) {
      var target = this.items.filter((e) => e.id == id);
      if (this.displayType == "step_form") {
        if (this.confirm) {
          this.conditions = [];
          this.conditions.push(target[0].id);
        }
      }
      if (status == "show") {
        target[0].is_hide = 0;
      } else if (status == "required") {
        target[0].is_required = 1;
      }
    },

    // 条件発動時（複数選択肢）
    changeItems(obj) {
      this.conditions = [];
      var self = this;
      Object.keys(obj).forEach(function (key) {
        var target = self.items.filter((e) => e.id == key);
        if (self.confirm) {
          self.conditions.push(target[0].id);
        }
        if (obj[key] == "show") {
          target[0].is_hide = 0;
        } else if (obj[key] == "required") {
          target[0].is_required = 1;
        }
      });
    },

    // 条件解除時
    removeItem(id, status) {
      var self = this;
      var target = self.items.filter((e) => e.id == id);
      if (self.confirm) {
        self.removeCondition(target[0].id);
      }
      if (status == "show") {
        target[0].is_hide = 1;
        if (target[0].type == "checkbox") {
          target[0].value = [];
          target[0].options.forEach(function (v) {
            if (Object.keys(v.condition).length > 0) {
              self.removeItem(v.condition.action, v.condition.do);
            }
          });
        } else {
          target[0].value = "";
          if (target[0].type == "radio" || target[0].type == "select") {
            target[0].options.forEach(function (v) {
              if (Object.keys(v.condition).length > 0) {
                self.removeItem(v.condition.action, v.condition.do);
              }
            });
          }
        }
      } else if (status == "required") {
        target[0].is_required = 0;
      }
    },
  },
};
</script>

<style lang="scss"></style>
