import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import plugins from "./plugins";
import { RepositoryFactory } from "./repositories/repositoryFactory";
import VueMoment from "vue-moment";
import VueI18n from "vue-i18n";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { data } from "./assets/lang/index";
import "./assets/css/iv-form.css";

Vue.config.productionTip = false;

/** リポジトリパターンの登録 */
Vue.prototype.$repository = RepositoryFactory;

/** languageファイルの設定 */
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "ja",
  messages: data,
});

/** sweetalertの設定 */
const options = {
  confirmButtonColor: "#6e7881",
};

/** ブラウザバックを検知 */
Vue.prototype.$browserBackFlg = false;
history.replaceState(null, "", null);
window.addEventListener("popstate", function () {
  Vue.prototype.$browserBackFlg = true;

  window.setTimeout(() => {
    Vue.prototype.$browserBackFlg = false;
  }, 500);
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(plugins);
Vue.use(VueMoment);
Vue.use(VueSweetalert2, options);

new Vue({
  router,
  store,
  i18n: i18n,
  render: (h) => h(App),
}).$mount("#app");
