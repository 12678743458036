import Repository from "./repository";
import store from "@/store/index.js";

export default {
  store(answers) {
    const appId = store.state.appId;
    const formId = store.state.formId;
    return Repository.post(`${appId}/${formId}/answers/store`, answers);
  },
};
