<template>
  <validation-observer tag="div" ref="observer" v-slot="{ invalid }">
    <b-form
      onsubmit="return false;"
      class="position-relative mb-5"
      :style="{ height: height }"
    >
      <transition
        v-for="item in items"
        :key="item.id"
        mode="out-in"
        :name="animation"
      >
        <div
          v-show="item.id == id"
          class="form-group position-absolute w-100"
          style="top: 0"
        >
          <div v-if="item.is_hide === 0">
            <div
              :is="componentSearch(item.type)"
              :item="item"
              :zip="zip"
              @height="getHeight"
              @action="activeItem"
              @change="changeItems"
              @remove="removeItem"
              @zipserver="setAddress"
              @disabled="validCheck"
            ></div>
          </div>
        </div>
      </transition>
    </b-form>
    <b-row class="iv-form__step--fields d-flex bd-highlight mb-5">
      <b-col cols="6" md="3" lg="4" xl="3" class="bd-highlight">
        <b-button
          @click="prev()"
          class="btn iv-btn iv-btn__back w-100"
          :disabled="!prevButton"
          >{{ $t("form.back") }}</b-button
        >
      </b-col>
      <b-col
        v-if="nextButton"
        cols="6"
        md="3"
        lg="4"
        xl="3"
        class="bd-highlight ml-auto"
      >
        <b-button
          @click="next()"
          class="btn iv-btn iv-btn__submit w-100"
          :disabled="validation"
          >{{ $t("form.next") }}</b-button
        >
      </b-col>
      <b-col
        v-if="confirmButton"
        cols="6"
        md="3"
        lg="4"
        xl="3"
        class="bd-highlight ml-auto"
      >
        <b-button
          @click="onSubmit"
          class="iv-btn iv-btn__submit w-100"
          :disabled="invalid"
        >
          {{ $t("form.confirm") }}
        </b-button>
      </b-col>
    </b-row>
    <b-progress :max="maxNum" height="10px" variant="secondary">
      <b-progress-bar :value="itemNum"> </b-progress-bar>
    </b-progress>
    <div class="text-center">
      <span>
        <strong>{{ itemNum }} / {{ maxNum }}</strong>
      </span>
    </div>
  </validation-observer>
</template>

<script>
export default {
  props: {
    items: Array,
    zip: Boolean,
    conditions: Array,
  },

  data() {
    return {
      id: "",
      animation: "",
      height: "",
      nextNum: "",
      prevNum: "",
      nextButton: true,
      prevButton: false,
      confirmButton: false,
      confirm: false,
      itemNum: "",
      maxNum: "",
      validation: false,
      backPages: false,
    };
  },

  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        this.idCheck();
        this.itemCount();
      }
    },
  },

  mounted() {
    this.confirm = this.$store.state.confirm;
    this.idCheck();
    this.itemCount();
  },

  methods: {
    getHeight(val) {
      this.height = val + "px";
    },

    // 設問ごとのバリデーションチェック
    validCheck(val) {
      this.validation = val;
    },

    // 動的コンポーネントの定義
    componentSearch(name) {
      return "base-" + name;
    },

    idCheck() {
      // idの設定
      this.id = this.$route.query.id;
      this.buttonAction();

      // indexを設定
      var result;
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].id == this.id) {
          result = i;
          break;
        }
      }
      const index = result;

      // 次の設問idを設定
      // 修正時、条件が発動した場合に直接設問へ遷移する
      if (this.confirm && this.conditions.length > 0) {
        this.nextButton = true;
        this.confirmButton = false;
        var id = this.conditions[0];
        this.nextNum = id;
      } else {
        if (this.items[index + 1]) {
          const arrayLength = this.items.length;
          for (let i = 1; i < arrayLength - index; i++) {
            if (this.items[index + i].is_hide === 0) {
              this.nextNum = this.items[index + i].id;

              if (this.backPages) {
                this.nextButton = true;
                this.confirmButton = false;
                this.backPages = false;
              } else if (this.confirm) {
                this.nextButton = false;
                this.confirmButton = true;
              } else {
                this.nextButton = true;
                this.confirmButton = false;
              }
              break;
            } else {
              this.nextButton = false;
              this.confirmButton = true;
            }
          }
        } else {
          this.nextButton = false;
          this.confirmButton = true;
        }
      }
      // console.log("next-" + this.nextNum);

      // 前の設問idを設定
      if (this.items[index - 1]) {
        for (let i = 1; i <= index; i++) {
          if (this.items[index - i].is_hide === 0) {
            this.prevNum = this.items[index - i].id;
            this.prevButton = true;
            break;
          } else {
            this.prevButton = false;
          }
        }
        // console.log("prev-" + this.prevNum);
      } else {
        this.prevButton = false;
      }
    },

    itemCount() {
      let count = this.items.reduce((prev, item) => {
        if (item.id == this.id) {
          this.itemNum = prev + 1;
        }
        return prev + (item.is_hide == 0 ? 1 : 0);
      }, 0);
      this.maxNum = count;
    },

    buttonAction() {
      var target = this.items.filter((e) => e.id == this.id);
      if (
        (target[0].is_required == 1 && !target[0].value) ||
        (target[0].is_required == 1 && target[0].value.length === 0)
      ) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },

    async prev() {
      await (this.animation = "right-slide");
      if (this.confirm) {
        this.backPages = true;
      }
      this.$router.push({
        // query: { id: this.prevNum },
        query: Object.assign({}, this.$route.query, { id: this.prevNum }),
      });
    },

    async next() {
      await (this.animation = "left-slide");

      if (this.confirm && this.conditions.length > 0) {
        this.$emit("remove-condition", this.nextNum);
      }
      this.$router.push({
        // query: { id: this.nextNum },
        query: Object.assign({}, this.$route.query, { id: this.nextNum }),
      });
    },

    setAddress(val, obj) {
      this.$emit("set-address", val, obj);
    },

    async activeItem(id, status) {
      await this.$emit("active-item", id, status);
      this.idCheck();
    },

    async removeItem(id, status) {
      await this.$emit("remove-item", id, status);
      this.idCheck();
    },

    async changeItems(obj) {
      await this.$emit("change-items", obj);
      this.idCheck();
    },

    onSubmit() {
      this.$emit("set-answers");
    },
  },
};
</script>

<style lang="scss"></style>
