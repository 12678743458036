<template>
  <validation-observer tag="div" ref="observer">
    <b-form onsubmit="return false;" autocomplete="off">
      <hr v-if="!$store.state.disableHeadline" />
      <div v-for="item in items" :key="item.id" class="form-group">
        <div
          v-if="item.is_hide === 0"
          :is="componentSearch(item.type)"
          :item="item"
          :zip="zip"
          @action="activeItem"
          @change="changeItems"
          @remove="removeItem"
          @zipserver="setAddress"
        ></div>
      </div>
      <hr />
      <b-row class="justify-content-center my-5">
        <b-col cols="8" md="4">
          <b-button @click="onSubmit" class="iv-btn iv-btn__submit w-100">
            {{ $t("form.confirm") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
export default {
  props: {
    items: Array,
    zip: Boolean,
  },

  methods: {
    // 動的コンポーネントの定義
    componentSearch(name) {
      return "base-" + name;
    },

    setAddress(val, obj) {
      this.$emit("set-address", val, obj);
    },

    activeItem(id, status) {
      this.$emit("active-item", id, status);
    },

    removeItem(id, status) {
      this.$emit("remove-item", id, status);
    },

    changeItems(obj) {
      this.$emit("change-items", obj);
    },

    async onSubmit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        var element = document.getElementsByClassName("iv-invalid");
        var height = element[0].offsetTop;
        window.scrollTo({
          top: height,
        });
      } else {
        this.$emit("set-answers");
      }
    },
  },
};
</script>

<style lang="scss"></style>
