import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

const required = "この項目は必須項目です。";
const notFormat = "有効な形式ではありません。";

extend("tel", {
  message: notFormat,
  validate(value) {
    if (
      value.match(/^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/) ||
      value.match(/^0[0-9]{8,10}$/)
    ) {
      return true;
    }
  },
});

extend("zip", {
  message: notFormat,
  validate(value) {
    if (
      value.match(/^[0-9]{3}[-][0-9]{4}$/) ||
      value.match(/^[0-9]{3}[0-9]{4}$/)
    ) {
      return true;
    }
  },
});

localize("ja", ja);

localize({
  ja: {
    messages: {
      required: required,
      email: notFormat,
    },
  },
});

const GlobalValidation = {
  install(app) {
    app.component("ValidationObserver", ValidationObserver);
    app.component("ValidationProvider", ValidationProvider);
  },
};

export default GlobalValidation;
