import Vue from "vue";
import VueRouter from "vue-router";

import Global from "../layouts/GlobalComponent";
import Form from "../views/FormIndex";
import Confirm from "../views/Confirm";
import Complete from "../views/Complete";

import Error from "../system/Error.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:app_id/:form_id",
    redirect: { name: "form" },
    component: Global,
    children: [
      {
        path: "form",
        name: "form",
        component: Form,
      },
      {
        path: "confirm",
        name: "confirm",
        component: Confirm,
      },
      {
        path: "complete",
        name: "complete",
        component: Complete,
      },
    ],
  },
  {
    path: "/500",
    name: "500",
    component: Error,
  },
  {
    path: "/503",
    name: "503",
    component: Error,
  },
  {
    path: "/502",
    name: "502",
    component: Error,
  },
  {
    path: "*",
    name: "404",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
