<template>
  <div ref="targetHeight">
    <validation-provider
      tag="div"
      ref="provider"
      :vid="'checkbox-' + item.id"
      :name="item.title"
      :rules="{ required: requiredCheck }"
      v-slot="validationContext"
    >
      <label
        :for="'checkbox-' + item.id"
        class="iv-label mb-3"
        :class="{ 'iv-invalid': !getValidationState(validationContext) }"
      >
        {{ item.title }}
        <base-required :required="item.is_required" />
      </label>

      <b-form-checkbox
        v-for="option in item.options"
        :key="option.id"
        v-model="item.value"
        :value="option.label"
        :id="'checkbox-item-' + option.id"
        @change="onChange(option.id)"
        :state="getValidationState(validationContext)"
      >
        {{ option.label }}
      </b-form-checkbox>

      <b-form-invalid-feedback
        :id="'checkbox-feedback-' + item.id"
        class="d-block"
      >
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>

      <b-form-text :id="'checkbox-help-' + item.id">
        {{ item.annotation_text }}
      </b-form-text>
    </validation-provider>
  </div>
</template>

<script>
import height from "@/mixins/elementHeight";

export default {
  mixins: [height],

  props: {
    item: Object,
  },

  data() {
    return {
      condition: {},
    };
  },

  computed: {
    requiredCheck() {
      return this.item.is_required === 1 ? true : false;
    },
  },

  created() {
    var self = this;
    self.item.options.forEach(function (v) {
      if (self.item.value.length === 0) {
        self.$set(v, "checked", false);
      } else if (self.item.value.includes(v.label)) {
        self.$set(v, "checked", true);
      } else {
        self.$set(v, "checked", false);
      }
    });
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    async onChange(key) {
      const itemId = this.$route.query.id;
      if (this.item.id == itemId) {
        const field = await this.$refs.provider.validate();
        if (!field.valid) {
          this.$emit("disabled", true);
        } else {
          this.$emit("disabled", false);
        }
      }

      var tmp = this.item.options.filter((e) => e.id == key);
      tmp[0].checked = !tmp[0].checked;
      var condition = Object.keys(tmp[0].condition).length;
      if (condition > 0) {
        if (!tmp[0].checked) {
          this.$emit("remove", tmp[0].condition.action, tmp[0].condition.do);
        }
        this.searchCondition();
      }
    },

    searchCondition() {
      this.condition = {};
      var self = this;
      self.item.options.forEach(function (v) {
        if (v.checked && Object.keys(v.condition).length > 0) {
          self.$set(self.condition, v.condition.action, v.condition.do);
        }
      });
      this.$emit("change", this.condition);
    },
  },
};
</script>

<style lang="scss"></style>
