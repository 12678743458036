import BaseCheckbox from "../components/BaseCheckbox";
import BaseComment from "../components/BaseComment";
import BaseDatalist from "../components/BaseDatalist";
import BaseDatePicker from "../components/BaseDatePicker";
import BaseMail from "../components/BaseMail";
import BasePrefecture from "../components/BasePrefecture";
import BaseRadio from "../components/BaseRadio";
import BaseRange from "../components/BaseRange";
import BaseRequiredBadge from "../components/BaseRequiredBadge";
import BaseSelect from "../components/BaseSelect";
import BaseTel from "../components/BaseTel";
import BaseText from "../components/BaseText";
import BaseTextArea from "../components/BaseTextArea";
import BaseZipCode from "../components/BaseZipCode";

const GlobalComponents = {
  install(app) {
    app.component("base-checkbox", BaseCheckbox);
    app.component("base-comment", BaseComment);
    app.component("base-datalists", BaseDatalist);
    app.component("base-date", BaseDatePicker);
    app.component("base-email", BaseMail);
    app.component("base-prefecture", BasePrefecture);
    app.component("base-radio", BaseRadio);
    app.component("base-range", BaseRange);
    app.component("base-required", BaseRequiredBadge);
    app.component("base-select", BaseSelect);
    app.component("base-tel", BaseTel);
    app.component("base-text", BaseText);
    app.component("base-textarea", BaseTextArea);
    app.component("base-zipcode", BaseZipCode);
  },
};

export default GlobalComponents;
