<template>
  <div ref="targetHeight">
    <validation-provider
      tag="div"
      ref="provider"
      :vid="'zip-' + item.id"
      :name="item.title"
      :rules="{
        required: requiredCheck,
        zip: true,
      }"
      v-slot="validationContext"
    >
      <label
        :for="'zip-' + item.id"
        class="iv-label"
        :class="{ 'iv-invalid': !getValidationState(validationContext) }"
      >
        {{ item.title }}
        <base-required :required="item.is_required" />
      </label>

      <b-row>
        <b-col md="4" cols="8">
          <b-form-input
            :id="'zip-' + item.id"
            v-model="item.value"
            :placeholder="item.placeholder"
            :state="getValidationState(validationContext)"
            :aria-describedby="'zip-feedback-' + item.id"
            @input="onInput"
          ></b-form-input>

          <b-form-invalid-feedback
            :id="'zip-feedback-' + item.id"
            class="d-block"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col
          v-if="item.zipserver.is_use === 1 && zipserverUse === 1"
          cols="2"
        >
          <b-button
            v-if="zip === false"
            class="iv-btn iv-btn__submit"
            style="white-space: nowrap"
            disabled
          >
            <b-spinner small></b-spinner>
          </b-button>
          <b-button
            v-else
            @click="getAddress(item.value, item.zipserver)"
            class="iv-btn iv-btn__submit iv-btn__zipserver"
            style="white-space: nowrap"
            :disabled="!disabledCheck(item.value)"
          >
            {{ $t("form.search") }}
          </b-button>
        </b-col>
      </b-row>

      <b-form-text :id="'zip-help-' + item.id">
        {{ item.annotation_text }}
      </b-form-text>
    </validation-provider>
  </div>
</template>

<script>
import height from "@/mixins/elementHeight";

export default {
  mixins: [height],

  props: {
    item: Object,
    zip: Boolean,
  },

  data() {
    return {
      zipserverUse: "",
    };
  },

  computed: {
    requiredCheck() {
      return this.item.is_required === 1 ? true : false;
    },
  },

  created() {
    this.zipserverUse = this.$store.state.zipserverIsUse;
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    disabledCheck(val) {
      if (
        val.match(/^[0-9]{3}[-][0-9]{4}$/) ||
        val.match(/^[0-9]{3}[0-9]{4}$/)
      ) {
        return true;
      }
    },

    getAddress(val, obj) {
      this.$emit("zipserver", val, obj);
    },

    async onInput() {
      const itemId = this.$route.query.id;
      if (this.item.id == itemId) {
        const field = await this.$refs.provider.validate();
        if (!field.valid) {
          this.$emit("disabled", true);
        } else {
          this.$emit("disabled", false);
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
