<template>
  <b-badge v-if="required === 1" variant="danger" class="iv-required">
    {{ $t("form.required") }}
  </b-badge>
</template>

<script>
export default {
  props: {
    required: Number,
  },
};
</script>

<style lang="scss"></style>
